
import Sophie from '../images/Sophie.png'
import Jack from '../images/Jack.png'
import Aarav from '../images/Aarav.png'
import Neha from '../images/Neha.png'
import Vikram from '../images/Vikram.png'
import Emily from '../images/Emily.png'
import './WhatDoReadersSay.css'

const WhatDoReadersSay = () => {
    return (
<div  className='aboutUS-section'>
  <br/>
  <br/>
<div className="sectionTitle">What Do Readers Say..</div>
<br/>
<div 
className='imageLayoutBAO'
   
>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div
   

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Sophie} 
               />

</div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Sophie Williams, Software Developer <br/>
          (Mid-Level - USA)
          <p  className='tableTextReaderDesc'>
          "This e-book is a must-read! I was struggling with office 
          politics, and ‘Work, Politics & Growth’ gave me the tools
           to rise above it. It’s helped me focus on my growth and
            build stronger relationships with my colleagues."
          </p>
          </p>

          </div>
          
</div>
<br/>

<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div
            
>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Jack}


          />
          </div>
          <div
           className='tableTextReader'
          >
          <p style={{color:'#FFA31A'}}>
          Jack Thompson, Project Manager <br/>
          (Mid-Level - Australia )
         
          <p className='tableTextReaderDesc'>
          "‘Work, Politics & Growth’ offered practical advice that
          resonated deeply with my experiences. It’s helped me
           navigate complex workplace dynamics and align my
            team towards shared goals. Highly recommend for<br/>
             any professional looking to advance."
          </p>
          </p>
          </div>
          
</div>

</div>
<br/>
<br/>



<div 
className='imageLayoutBAO'
   
>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Aarav}


          />
          </div>
          <div
          className='tableTextReader'
          
          
          >
          <p style={{color:'#FFA31A'}}>
          Aarav Sharma, Marketing Associate <br/> 
          (Fresher - India)
          <p className='tableTextReaderDesc'>
          "‘Work, Politics & Growth’ has been a game-changer for
           me. As a newcomer, navigating office dynamics was
            daunting. This e-book provided clear strategies that 
            helped me establish myself confidently and avoid
             common pitfalls."
          </p>
          </p>
          </div>
          
</div>
<br/>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Emily}


          /></div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Emily Johnson, Financial Analyst <br/>
           (Fresher - UK)
          <p className='tableTextReaderDesc'>
          "As a recent graduate, I was anxious about dealing with 
          office politics. This e-book gave me the confidence and 
          strategies to not just survive but thrive. It's a valuable 
          guide for anyone entering the workforce."
          </p>
          </p>
          </div>
          
</div>
</div>


<br/>
<br/>



<div 
className='imageLayoutBAO'
   
>
<div className='TwoSecLayout'>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Neha}


          /></div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Neha Gupta, HR Specialist <br/>
          (Fresher - India)
          <p className='tableTextReaderDesc'>
          "Starting in HR, I knew office politics would be part of
           the job, but this e-book opened my eyes to the nuances
            of handling it professionally. It’s been instrumental in 
            shaping my approach and making a positive impact in
             my role."
          </p>
          </p>
          </div>
          
</div>
<br/>


<div className='TwoSecLayout'>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Vikram}


          /></div>
          <div
          className='tableTextReader'
          >
          <p style={{color:'#FFA31A'}}>
          Vikram Singh, Operations Supervisor <br/>(Mid-Level - India)
          <p className='tableTextReaderDesc'>
          "This e-book changed how I view and handle workplace 
          politics. It’s not just about playing the game; it’s about 
           advantage. ‘Work, Politics & Growth’ has been a key 
           resource in my career progression."
          </p>
          </p>
          </div>
          
</div>
</div>
<br/>

</div>)
}



export default WhatDoReadersSay;