
import './ContactUs.css'
import emailLogo from '../images/emailLogo.png'
import PhoneLogo from '../images/PhoneLogo.png'
const ContactUs = () => {
    return (
<div className='aboutUS-section'>
<br/>
<br/>

  <div className="sectionTitle">Contact us</div>
  <div className="contentDescContact">
    
  <div 
    className="contactDiv"
  >
    <br/>

    <div>
      <p  className="contactSec" style={{color:'#FFA31A'}}>Quick Links</p>
        <ul>
          <li ><a className="contactSecLink" href='/privacy'>Privacy Policy</a></li>
          <li><a className="contactSecLink" href='/terms'>Terms of Use</a></li>
          <li><a className="contactSecLink" href='/shipping'>Shipping & Delivery Policy </a></li>
          <li><a className="contactSecLink" href='/cancel'>Cancellation & Refund Policies</a></li>
        </ul>
        {/* <p>Follow us at</p>
        <div>
<a href='https://www.facebook.com/profile.php?id=61555974775883&mibextid=ZbWKwL'>
        <img
  className="imageLogoLink"

    alt="Facebook"
    src={fbLogo}


  />
  </a>
<a href='https://www.instagram.com/genzvisionofficial/'>

  <img 
  
  className="imageLogoLink"
    alt="Instagram"
    src={instaLogo}
  />
  </a>
  <a
  href='https://www.youtube.com/channel/UCgzigpQe-C3hJxolZZrTGSw'
  >
   <img
             className="imageLogoLink"

    alt="YouTube"
    src={youtubeLogo}
  />
  </a>
        </div> */}

    </div>
    <br/>
    <div className="contactSec">
   <p  style={{color:'#FFA31A'}}> Available at</p>
   <div style={{display:'flex',alignItems:'center'}}>
   <img
    className='contactImage'
    alt=""
    src={emailLogo}
  />  
  <span className="contactSecLink">
  
support@stuffenzy.com
  </span>
  </div>
  <div style={{display:'flex',alignContent:'center',alignItems:'center'}}>
  <img
   className='contactImage'
  
    alt=""
    src={PhoneLogo}
  />  
  <span className="contactSecLink">+91 93214 22611</span>
</div>
<br/>
    <p className='address'>
    STUFFENZY LLP, 46 LF BLDG 5B CTS, 
1A12B WI LINKING RD,<br></br>
OPP OSHIWARA DEPOT, GOREGAON WEST,<br></br>
MUMBAI-400104,
MAHARASHTRA INDIA<br></br>
    </p>
    </div>
  </div>
  
    </div>

    <br></br>
    <br></br>
    <div style={{display:'flex',justifyContent:'center'}}>
    <footer className="footer"> © Stuffenzy LLP, 2024. All rights reserved. </footer>
<br/>
    </div>

</div>
    )}

export default ContactUs;
